<template>
    <modal ref="modalGanadoresRecompensas" titulo="Ganadores de las recompensas" tamano="modal-xl" no-aceptar no-cancelar>
        <div class="row mx-0">
            <div class="col-12">
                <el-table
                :data="tableData"
                style="width: 100%"
                header-row-class-name="text-general f-16 f-500"
                >
                    <el-table-column
                    prop="cliente"
                    label="Cliente"
                    min-width="180"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="leechero"
                    label="Vendedor"
                    width="180"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="cedis"
                    label="Cedis"
                    min-width="180"
                    class-name="text-center"
                    />
                    <!-- prop="monedas" -->
                    <el-table-column
                    min-width="180"
                    class-name="text-center"
                    >
                        <template slot="header">
                            <div class="row mx-0 align-items-center justify-center">
                                <img src="/img/gaming/i_moneda.svg" height="20" class="mr-2" />
                                <span class="text-general f-500 f-16">
                                    Monedas
                                </span>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            {{ scope.row.monedas }}
                        </template>
                    </el-table-column>
                    <!-- prop="cedis" -->
                    <el-table-column
                    min-width="180"
                    class-name="text-center"
                    >
                        <template slot="header">
                            <div class="row mx-0 align-items-center justify-center">
                                <img src="/img/gaming/i_gema.svg" height="20" class="mr-2" />
                                <span class="text-general f-500 f-16">
                                    Monedas
                                </span>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            {{ scope.row.gemas }}
                        </template>
                    </el-table-column>
                    <el-table-column
                    prop="pedidos"
                    label="Pedidos"
                    min-width="180"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="ultimo_pedido"
                    label="Último pedido"
                    min-width="180"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="valor"
                    label="Valor"
                    min-width="180"
                    class-name="text-center"
                    />
                    <el-table-column
                    prop="inscripcion"
                    label="Inscripción"
                    min-width="180"
                    class-name="text-center"
                    />
                </el-table>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    data(){
        return {
            tableData: [{
                cliente: 'Carlos Aguilar',
                monedas: '$ 587.000',
                gemas: '$ 587.000',
                leechero: 'Daniel avila',
                cedis: 'Bucaramanga',
                pedidos: 26,
                ultimo_pedido: '01 Jul. 2020',
                valor: '$ 587.000',
                inscripcion: '01 Jul. 2020'
            }, {
                cliente: 'Carlos Aguilar',
                monedas: '$ 587.000',
                gemas: '$ 587.000',
                leechero: 'Daniel avila',
                cedis: 'Bucaramanga',
                pedidos: 26,
                ultimo_pedido: '01 Jul. 2020',
                valor: '$ 587.000',
                inscripcion: '01 Jul. 2020'
            }, {
                cliente: 'Carlos Aguilar',
                monedas: '$ 587.000',
                gemas: '$ 587.000',
                leechero: 'Daniel avila',
                cedis: 'Bucaramanga',
                pedidos: 26,
                ultimo_pedido: '01 Jul. 2020',
                valor: '$ 587.000',
                inscripcion: '01 Jul. 2020'
            }, {
                cliente: 'Carlos Aguilar',
                monedas: '$ 587.000',
                gemas: '$ 587.000',
                leechero: 'Daniel avila',
                cedis: 'Bucaramanga',
                pedidos: 26,
                ultimo_pedido: '01 Jul. 2020',
                valor: '$ 587.000',
                inscripcion: '01 Jul. 2020'
            }]
        }
    },
    methods: {
        toggle(){
            this.$refs.modalGanadoresRecompensas.toggle();
        }
    }
}
</script>
